@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-latin-400-normal.7cf38e2c.woff2") format("woff2"), url("open-sans-latin-400-normal.9bf42e24.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-cyrillic-600-normal.b3f9a469.woff2") format("woff2"), url("open-sans-cyrillic-600-normal.2833ad51.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-cyrillic-400-italic.b983ed0c.woff2") format("woff2"), url("open-sans-cyrillic-400-italic.aa4eed1f.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-cyrillic-400-normal.09174fa0.woff2") format("woff2"), url("open-sans-cyrillic-400-normal.879f461a.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-latin-600-normal.e0a47358.woff2") format("woff2"), url("open-sans-latin-600-normal.3f41d989.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-latin-400-italic.c6bc0c02.woff2") format("woff2"), url("open-sans-latin-400-italic.acbf2194.woff") format("woff");
}

/*# sourceMappingURL=index.7d22f5b5.css.map */
